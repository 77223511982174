.BootstrapMulti .slick-arrow.slick-prev,
.BootstrapMulti .slick-arrow.slick-next {
  background: #1d1d24;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}
.BootstrapMulti .slick-prev::before,
.BootstrapMulti .slick-next::before {
  display: none;
}
.BootstrapMulti .slick-arrow.slick-prev {
  left: -45px;
}
.BootstrapMulti .slick-arrow.slick-next {
  right: -45px;
}
.PrevArrow {
  margin-left: 15px !important;
}
.MuiSvgIcon-root button {
  outline: none;
  border: none;
  color: #1d1d24;
  background: white;
  border-radius: 20px;
  border: 1px solid #1d1d24;
  font-size: 11px;
  font-weight: 500;
  padding: 7px 14px;
  margin: 10px 0;
  text-transform: uppercase;
}
button:hover {
  background: #1d1d24;
  color: white;
  cursor: pointer;
}
.BootstrapMulti .slick-dots li button:before {
  font-size: 15px;
}
