/*====================
    Breadcrump Area 
======================*/

@media (min-width: 1200px) {
  .container {
    max-width: 1260px;
  }
}

.breadcrumb-inner {
  text-align: center;
  h2 {
    &.title {
      background: #f81f01;
      background: linear-gradient(145deg, #ff5d00 0%, #faa831 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 40px;

      @media #{$lg-layout} {
        font-size: 35px;
      }
      @media #{$md-layout} {
        font-size: 35px;
      }
      @media #{$sm-layout} {
        font-size: 25px;
        // line-height: 59px;
      }
    }
  }
  ul {
    &.page-list {
      @extend %liststyle;
      li {
        display: inline-block;
        color: #fff;
        a {
          color: #fff;
        }
        &.breadcrumb-item {
          &.active {
            color: $text-color-2;
          }
        }
      }
    }
  }
}

.breadcrupm-style--2 {
  h2 {
    &.title {
      text-transform: uppercase;
      font-size: 72px;
      @media #{$sm-layout} {
        font-size: 40px;
        line-height: 59px;
      }
    }
  }
  span {
    font-size: 22px;
    color: #c6c9d8;
  }
}

.rn-page-title {
  h2 {
    &.title {
      font-size: 72px;
      @media #{$lg-layout} {
        font-size: 56px;
      }
      @media #{$md-layout} {
        font-size: 50px;
      }
      @media #{$sm-layout} {
        font-size: 40px;
        line-height: 59px;
      }
    }
  }
  p {
    font-size: 22px;
    line-height: 27px;
    color: #c6c9d8;
  }
}

.backto-top {
  > div {
    z-index: 999;
    width: 50px;
    height: 50px;
    line-height: 49px;
    border-radius: 50%;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    z-index: 999 !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }
  svg {
    font-size: 14px !important;
    color: #222222;
    width: 27px;
    height: 27px;
  }
}
.backto-top-home {
  > div {
    z-index: 100;
    width: 50px;
    height: 50px;
    // position: relative;
    margin-bottom: 20px;
    line-height: 49px;
    border-radius: 50%;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    z-index: 100 !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }
  svg {
    font-size: 14px !important;
    color: #222222;
    width: 27px;
    height: 27px;
  }
}
.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 15px;
  right: 30px;
  line-height: 49px;
  background-color: #25d366;
  color: #fff !important;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.float:hover {
  text-decoration: none;
  color: #25d366;
  background-color: #fff;
}

.my-float {
  text-align: center;
  margin-top: 13px;
}
